body {
  background-color: $brand-white;
  font-family: "Open Sans", sans-serif !important;
  color: $brand-slate;

  hr.muted {
    border: none;
    border-bottom: 1px solid $brand-slate;
  }

  #main-title {
    padding-bottom: 4em;

    h1 {
      font-size: 45px;
      color: $brand-slate;
      font-family: "Open Sans", sans-serif;
      font-weight: 100;
    }
  }

  h1,
  h2 {
    font-family: "Open Sans", sans-serif;
  }

  h3,
  h4,
  h5,
  h6 {
    font-family: "Open Sans", sans-serif;
    line-height: 1.5;
  }

  p {
    color: $brand-slate;
  }

  #section {
    padding-top: 100px;

    #section-title {
      margin-bottom: 20px;

      h2 {
        margin: 0 0 1em;
        font-size: 28px;
        font-weight: 300;
        color: $brand-red;
      }
    }

    #section-count {
      margin: 0.5em 0;
      height: 2em;
      font-size: 1em;
      line-height: 2;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      color: $brand-slate;

      span {
        font-size: 0.75em;
        color: lighten($brand-slate, 30%);
      }
    }
  }

  h3 {
    font-weight: 700;
    color: lighten($brand-slate, 20%);
    font-size: 18px;
  }

  footer {
    width: 100%;
    display: block;
    padding: 2em 1em 4em;
    margin-top: 8em;
    border-top: 1px solid $brand-slate-light;

    #survey-title,
    #survey-credit,
    #cookie-and-privacy-policy {
      color: $brand-slate;
      padding-top: 1em;
      font-size: 14px;
    }

    #survey-title {
      text-align: left;
    }

    #survey-credit {
      text-align: right;
    }

    #cookie-and-privacy-policy {
      text-align: center;

      a.last {
        margin-left: 20px;
      }
    }

    #logo {
      height: 35px;
      margin: 10px 10px 0px 0px;
    }

    #footer-security-seal {
      margin-top: 15px;
    }
  }

  img.img-bordered {
    border: 5px solid lighten($brand-white, 10%);
  }
}

.separator {
  color: $brand-black;
  font-size: 20px;
  text-align: center;
}

::-webkit-input-placeholder {
  color: lighten($brand-slate-light, 20%);
}

::-moz-placeholder {
  color: lighten($brand-slate-light, 20%);
}

:-ms-input-placeholder {
  color: lighten($brand-slate-light, 20%);
}

:-moz-placeholder {
  color: lighten($brand-slate-light, 20%);
}

.upload-your-file {
  background: rgba($brand-slate-light, 0.25);
  padding: 3em;

  .form-group {
    margin: 0;
  }
}

.table.wage-column-example {
  margin: 20px auto 40px;
  width: 95%;
}

.table.wage-column-example tbody tr td:not(:first-child) {
  border-left: 1px solid #ddd;
}

.text-link {
  padding: 0.75em 1.25em;
  color: lighten($brand-slate, 20%);
  text-align: center;
  font-weight: bold;
  display: inline-block;

  &:hover {
    color: $brand-slate;
  }
}

.selected-icon {
  color: $active-color !important;
}

// Shepherd Stuff
body::after {
  opacity: 0;
}

body.shepherd-active::after {
  content: "";
  display: block;
  background-color: $brand-slate;
  opacity: 0.2;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000000;
}

.shepherd-element.shepherd-open,
.shepherd-target.shepherd-enabled {
  z-index: 10000000;
  position: relative;
}

.description-library {
  margin-bottom: 5em;
}

#participation-agreement-text {
  color: $brand-black !important;
  border-radius: 2px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    color: lighten($brand-slate, 30%);
  }
  p,
  ul,
  ol {
    color: $brand-slate !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8em;
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 1em;
  }
}
#participantion-agreement-radios {
  margin-top: 20px;
  input[type="radio"] {
    margin-right: 10px;
  }
}

.btn-primary {
  color: $brand-white !important;
  background-color: $brand-slate !important;
  box-shadow: 0px 5px 10px 1px darken($brand-white, 7%);
  font-weight: 500;
}

.btn-primary:hover {
  background-color: darken($brand-slate, 5%) !important;
  box-shadow: none;
}

.btn,
.button {
  margin: 1em 0;
  display: inline-block;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1;
  padding: 0.875em 1em 0.875em;
  color: #fff;
  background: #e4252a;
  border: 2px solid #e4252a;
  transition: ease all 0.25s;
  position: relative;
  font-family: sans-serif;
  text-decoration: none;
}
.btn:hover,
.button:hover {
  transform: translateY(-2px) !important;
  color: #fff;
  background: #d51a1f;
  border: 2px solid #d51a1f;
  text-decoration: none;
}

.tooltip-inner {
  background-color: $brand-slate-light;
  color: $brand-black;
}

.tooltip.top .tooltip-arrow {
  border-top-color: $brand-slate-light;
}

#import-nav button {
  color: $brand-white !important;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
}

#contact-support-form {
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 60px 25%;
  z-index: 10000;
  background-color: darken($brand-white, 5%);
  box-shadow: 0px 5px 100px $brand-black;
  right: 0px;
  #nav-buttons {
    .nav-button,
    .circle-icon {
      background-color: transparent !important;
    }
  }
  h1 {
    font-weight: 100;
    color: $brand-red;
    margin-bottom: 30px;
  }
  form {
    .form-group {
      margin-bottom: 30px;
      label {
        color: $brand-slate !important;
      }
      input {
        background-color: $brand-white !important;
        color: $brand-black !important;
        padding: 0.5em !important;
      }
    }
  }
}

.form-group {
  margin-bottom: 80px;
  color: $brand-slate !important;
  input {
    transition: ease all 0.25s;
    background: $brand-white !important;
    color: $brand-slate !important;
    border: none !important;
    border-bottom: 2px solid $brand-slate !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    outline: none;
    font-size: 22px;
    font-weight: 100;
    line-height: 1.6;
  }

  input[type="radio"] {
    margin-right: 10px;
  }

  input[type="file"] {
    padding-top: 10px;
    border: none !important;
    background: none !important;
    font-size: 18px;
  }
  select {
    margin-top: 15px;
    padding: 5px;
    background-color: $brand-white !important;
    color: $brand-slate !important;
    border: 2px solid $brand-slate !important;
    line-height: 100px;
    outline: none !important;
  }
  label {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    color: $brand-slate !important;
    transition: ease all 0.25s;
    display: block;
  }
  span.instructions {
    display: block;
    font-style: italic;
    padding-bottom: 1em;
    color: lighten($brand-slate, 10%);
  }
}

div.radio {
  padding-left: 20px;
  label.radio {
    font-weight: 300;
    color: darken($brand-slate, 20%) !important;
    margin-bottom: 15px;
    font-size: 17px;
  }
}

.form-group.active {
  color: $brand-slate !important;
  input {
    border-bottom: 2px solid $brand-red !important;
    background: rgba($brand-slate-light, 0.2) !important;
  }
  input[type="text"],
  input[type="tel"],
  input[type="email"] {
    padding: 12px 18px !important;
    font-size: 22px;
    font-weight: 100;
    line-height: 1.6;
  }
  input[type="file"] {
    border: none !important;
    background: none !important;
  }

  input[type="radio"] {
  }

  select {
    color: $brand-slate !important;
    border: 2px solid $brand-slate !important;
  }

  label {
    color: $brand-black !important;
  }

  div.radio {
    label.radio {
      color: $brand-slate !important;
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  .form-group.active:last-child {
    padding-bottom: 0px;
  }
}

.form-group.completed {
}

.instructions {
  .instructions-title {
    color: rgba($brand-slate, 0.6);
    font-size: 14px;
    margin-bottom: 20px;
    font-style: italic;
  }
  .instructions-body {
  }
}

hr.progress-hr {
  border: none;
  margin: 0;
  padding: 0;
}
hr#progress-base {
  border-bottom: 1px solid $active-color;
}

hr#progress-overlay {
  border-bottom: 10px solid $active-color;
  position: relative;
  top: -11px;
}

div.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  background-color: transparentize(darken($brand-white, 10%), 0.3);
  font-size: 6em;
  padding-top: 15%;
  text-align: center;
  color: $brand-slate-light;
}

.summary-body {
  margin: 40px auto;
}

.toast-title {
  font-weight: bold;
}
.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: $brand-black;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: $brand-black;
  -webkit-text-shadow: 0 1px 0 $brand-black;
  text-shadow: 0 1px 0 $brand-black;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: $brand-black;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 60px;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  /*overrides*/
}
#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  background-position: 15px center;
  background-repeat: no-repeat;
  color: $brand-black;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
#toast-container > :hover {
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}
#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}
#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}
#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}
#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}
#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.toast {
  background-color: #030303;
}
.toast-success {
  background-color: #51a351;
}
.toast-error {
  background-color: #ffe45f;
}
.toast-info {
  background-color: #2f96b4;
}
.toast-warning {
  background-color: #f89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: $brand-black;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
